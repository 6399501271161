angular.module('LeasePilot').directive('colResizeable', function() {
  return {
    restrict: 'A',
    link: function(scope, elem, attrs) {
      var disabledColumns = [];
      if (attrs.disabledColumns && attrs.disabledColumns.length) {
        var disabledColumns = attrs.disabledColumns.split(',').map(Number);
      }

      scope.enable = function() {
        elem.closest('table').colResizable({
          liveDrag: true,
          hoverCursor: 'col-resize',
          gripInnerHtml: '<div class="grip"></div>',
          draggingClass: 'dragging',
          minWidth: 100,
          disabledColumns: disabledColumns,
        });
      };

      scope.disable = function() {
        elem.closest('table').colResizable({
          disable: true,
        });
      };

      scope.enable();

      elem.on('dataChanged', function() {
        scope.disable();
        scope.enable();
      });
    },
  };
});
